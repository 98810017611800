<template>
     <div>
          <div class="">
               <div class="banner ">
                    <div class="container">
                         <h1>How it works</h1>
                         <h2>Nestease Partner</h2>
                    </div>
               </div>

               <div class="container content">
                    <div class="columns is-vcentered">
                         <div class="column">
                              <h2>
                                   Signing Up
                              </h2>

                              <div class="steps ">
                                   <div class="columns is-mobile  stepitem ">
                                        <div class="count">1</div>
                                        <div class="column">
                                             <div class="step">
                                                  Download the "Nestease Partner" App from
                                                  <a href="https://apps.apple.com/in/app/nestease-partner/id1539485053" target="_blank">
                                                       App Store
                                                  </a>
                                                  or
                                                  <a
                                                       href="https://play.google.com/store/apps/details?id=com.nestease.partner"
                                                       target="_blank"
                                                  >
                                                       Google Play Store</a
                                                  >.
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns  is-mobile  stepitem">
                                        <div class="count">2</div>
                                        <div class="column">
                                             <div class="step">
                                                  Enter your address, mobile phone, email, serving miles range, and lawn mower type.
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns  is-mobile  stepitem">
                                        <div class="count">3</div>
                                        <div class="column">
                                             <div class="step">Enter your bank details for payment via Stripe integrated with the App.</div>
                                        </div>
                                   </div>

                                   <div class="columns is-mobile stepitem ">
                                        <div class="count">4</div>
                                        <div class="column">
                                             <div class="step">
                                                  Add the crew members (if any) who will be doing the job at the customer site. They will be receiving
                                                  an invitation notification on their phone to onboard to Nestease Partner. Crew members completing
                                                  the job must utilize the Nestease Partner App to take before and after photos. Press “Start” before
                                                  beginning the job. Select “Complete” and “Done” when you have finished the job.
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns  is-mobile  stepitem">
                                        <div class="count">5</div>
                                        <div class="column">
                                             <div class="step">Upload your Federal or State Issued ID and Insurance information.</div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div class="column">
                              <img src="@/assets/images/howitworks/howitworks1.jpg" alt="" />
                         </div>
                    </div>
               </div>

               <div class="container content">
                    <div class="columns  ">
                         <div class="column is-hidden-mobile">
                              <img src="@/assets/images/howitworks/howitworks2.jpg" alt="" />
                         </div>
                         <div class="column">
                              <h2>
                                   Post Signup
                              </h2>

                              <div class="steps ">
                                   <div class="columns  is-mobile stepitem  ">
                                        <div class="count">1</div>
                                        <div class="column">
                                             <div class="step">
                                                  Open the app and ensure you are online.
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns  is-mobile  stepitem   ">
                                        <div class="count">2</div>
                                        <div class="column">
                                             <div class="step">
                                                  Accept requests
                                                  <ul>
                                                       <li>Once you are logged in, Providers can accept service requests from the “Requests” tab.</li>
                                                       <li>Once the request is accepted, it will be moved to the Accepted tab.</li>
                                                       <li>
                                                            If you have crew members, they will also get the requests in their Accepted tab. Only the
                                                            main provider can accept the job, crew members cannot.
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns  is-mobile  stepitem  ">
                                        <div class="count">3</div>
                                        <div class="column">
                                             <div class="step">
                                                  Service your requests from the “Accepted” tab.

                                                  <ul>
                                                       <li>
                                                            Please make sure that you complete the requests on the desired date provided from the
                                                            customer.
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="columns is-mobile stepitem    ">
                                        <div class="count">4</div>
                                        <div class="column">
                                             <div class="step">
                                                  Once you arrive at the customer site, press the “start” button. The “start” and “done” buttons will
                                                  be used to determine time calculation for service.

                                                  <ul>
                                                       <li>
                                                            Photos will be required for each customer visit. Please ensure you allocate time to take
                                                            pictures before and after the customer service request, and upload your photos into the
                                                            Nestease Partner App to show completion.
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="columns is-mobile stepitem    ">
                                        <div class="count">5</div>
                                        <div class="column">
                                             <div class="step">
                                                  Once the work is complete and verified, the app will process payment from the customer’s provided
                                                  credit card information. Customers would be given 12 hours to verify the work completed by you.
                                                  Payment will be made weekly through the Stripe payment process.
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="column is-hidden-tablet">
                              <img src="@/assets/images/howitworks/howitworks2.jpg" alt="" />
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .banner {
          // background: url("../assets/images/about/about.jpg");
          background-color: rgba(0, 0, 0, 0.05);

          height: 300px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          display: flex;
          align-items: center;

          h1 {
               color: black;
               opacity: 0.8;
               font-size: 60px;
               text-align: center;

                      
          }

          h2 {
               padding-top: 30px;
               text-align: center;
               color: #03bc85;
          }
     }

     .content {
          margin: 100px auto;

          @media screen and (max-width: 804px) {
               margin: 70px auto;
          }

          h2 {
               font-size: 40px;
               padding-bottom: 20px;


                  @media screen and (max-width: 804px) {
                              text-align: center;
                         }
          }
     }

     .steps {
          a {
               display: inline-block;
          }
          margin-top: 30px;
          .columns {
               margin-bottom: 35px;
          }
          .count {
               background: #03cf92;
               color: white;
               width: 50px;
               height: 50px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 100%;
               font-size: 20px;
               font-weight: 600;
               font-family: "DM Sans", sans-serif;
          }
          .step {
               padding-left: 20px;
               @media screen and (max-width: 704px) {
                    padding-right: 0;
                    font-size: 17px;
                    font-weight: 500;
               }

               padding-right: 60px;
               font-weight: 600;
               font-size: 19px;
               text-align: justify;
          }
     }

     .container {
          padding-left: 16px;
          padding-right: 16px;
     }
</style>
