<template>
     <div class="home">
          <div class="banner ">
               <div class="container">
                    <div class="columns is-vcentered">
                         <div class="column  is-half ">
                              <div class="bannertext">
                                   <h1>
                                        Your Own Time
                                   </h1>
                                   <h1>
                                        Your Own Jobs
                                   </h1>
                                   <h1>Your Own Business</h1>
                                   <p>
                                        Start Earning today
                                        <!-- <span class="location">
                                             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                       d="M18.364 17.3639L12 23.7279L5.636 17.3639C4.37734 16.1052 3.52019 14.5016 3.17293 12.7558C2.82567 11.0099 3.00391 9.20035 3.6851 7.55582C4.36629 5.91129 5.51984 4.50569 6.99988 3.51677C8.47992 2.52784 10.22 2 12 2C13.78 2 15.5201 2.52784 17.0001 3.51677C18.4802 4.50569 19.6337 5.91129 20.3149 7.55582C20.9961 9.20035 21.1743 11.0099 20.8271 12.7558C20.4798 14.5016 19.6227 16.1052 18.364 17.3639ZM12 14.9999C13.0609 14.9999 14.0783 14.5785 14.8284 13.8283C15.5786 13.0782 16 12.0608 16 10.9999C16 9.93906 15.5786 8.92164 14.8284 8.17149C14.0783 7.42135 13.0609 6.99992 12 6.99992C10.9391 6.99992 9.92172 7.42135 9.17158 8.17149C8.42143 8.92164 8 9.93906 8 10.9999C8 12.0608 8.42143 13.0782 9.17158 13.8283C9.92172 14.5785 10.9391 14.9999 12 14.9999ZM12 12.9999C11.4696 12.9999 10.9609 12.7892 10.5858 12.4141C10.2107 12.0391 10 11.5304 10 10.9999C10 10.4695 10.2107 9.96078 10.5858 9.58571C10.9609 9.21064 11.4696 8.99992 12 8.99992C12.5304 8.99992 13.0391 9.21064 13.4142 9.58571C13.7893 9.96078 14 10.4695 14 10.9999C14 11.5304 13.7893 12.0391 13.4142 12.4141C13.0391 12.7892 12.5304 12.9999 12 12.9999Z"
                                                       fill="black"
                                                  />
                                             </svg>
                                             Virginia
                                        </span> -->
                                   </p>

                                   <div class="appstores">
                                        <a href="https://apps.apple.com/in/app/nestease-partner/id1539485053" target="_blank"
                                             ><img src="@/assets/images/appstore.png" alt=""
                                        /></a>

                                        <a href="https://play.google.com/store/apps/details?id=com.nestease.partner" target="_blank"
                                             ><img src="@/assets/images/playstore.png" alt=""
                                        /></a>
                                   </div>
                              </div>
                         </div>

                         <div class="column  is-half  handimg">
                              <img src="@/assets/images/partner/hand2.png" alt="" />
                         </div>
                    </div>
               </div>
          </div>

          <div class="howitworks">
               <div class="is-hidden-desktop">
                    <h2 class="  has-text-centered 	">
                         How the app works
                    </h2>

                    <div class="mobile steps">
                         <div class="columns is-mobile is-vcentered ">
                              <div class="count">1</div>
                              <div class="column "><div class="step">Enter your information</div></div>
                         </div>
                         <div class="columns  is-mobile is-vcentered">
                              <div class="count">2</div>
                              <div class="column"><div class="step">Accept customer requests</div></div>
                         </div>

                         <div class="columns  is-mobile is-vcentered">
                              <div class="count">3</div>
                              <div class="column"><div class="step">Complete the job on requested date</div></div>
                         </div>

                         <div class="columns is-mobile is-vcentered">
                              <div class="count">4</div>
                              <div class="column"><div class="step">Get paid real time</div></div>
                         </div>
                    </div>
               </div>
               <div class="columns is-vcentered">
                    <div class="column imgcol">
                         <!-- <img src="@/assets/images/demo.png" alt="" /> -->
                         <!-- 
                         <video
                              src="@/assets/video/video1.mp4"
                              controls
                              loop
                              autoplay
                              style="width:350px;border:none !important"
                              type="video/mp4"
                              ref="video"
                         ></video> -->

                         <!-- <Canvas-video
                              src="https://nestease-website.web.app/media/video1.a5009c67.mp4"
                              :autoplay="true"
                              style="height:1000px;;"
                              :loop="true"
                              :squeare="true"
                         ></Canvas-video> -->

                         <video-background
                              :src="require('@/assets/video/partner.mp4')"
                              :poster="require('@/assets/images/demo.png')"
                              style="height: 640px;width:320px;display:inline-block;"
                         >
                              <!-- <h1 style="color: white;">Hello welcome!</h1> -->
                         </video-background>
                    </div>
                    <div class="column textcol is-hidden-touch">
                         <h2>
                              How the app works
                         </h2>

                         <div class="steps ">
                              <div class="columns is-mobile is-vcentered ">
                                   <div><div class="count">1</div></div>
                                   <div class="column "><div class="step">Enter your information</div></div>
                              </div>
                              <div class="columns  is-mobile is-vcentered">
                                   <div class="count">2</div>
                                   <div class="column"><div class="step">Accept customer requests</div></div>
                              </div>

                              <div class="columns  is-mobile is-vcentered">
                                   <div class="count">3</div>
                                   <div class="column"><div class="step">Complete the job on requested date</div></div>
                              </div>

                              <div class="columns is-mobile is-vcentered">
                                   <div class="count">4</div>
                                   <div class="column"><div class="step">Get paid real time</div></div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="why">
               <h2>
                    Why nestease?
               </h2>

               <div class="container">
                    <div>
                         <p class="subtext">
                              We provide a platform for local businesses, mom & pop entities, and freelancers to earn additional income by connecting
                              them to new customers and neighborhoods.
                         </p>
                    </div>
                    <div class="columns is-multiline ">
                         <div class="column is-4 itemwhy">
                              <div class="">
                                   <div class=" iconcol">
                                        <img src="@/assets/images/partner/why1.png" class="lawnmowericon" alt="" />
                                   </div>

                                   <div class=" textcol ">
                                        <h3>Flexible Schedule</h3>
                                        <p>
                                             Work based on your availability. Choose the miles you want to serve. Start and stop when you want.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div class="column is-4 itemwhy">
                              <div class="">
                                   <div class=" iconcol">
                                        <img src="@/assets/images/partner/why3.png" class="lawnmowericon" alt="" />
                                   </div>

                                   <div class=" textcol  ">
                                        <h3>
                                             Real Time Payments
                                        </h3>
                                        <p>
                                             Payments made to your account within a few days after the job is complete. No more invoicing and tracking
                                             customers for payments.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div class="column is-4 itemwhy">
                              <div class="">
                                   <div class=" iconcol">
                                        <img src="@/assets/images/partner/savemoney.svg" class="lawnmowericon savemoneyicon" alt="" />
                                   </div>

                                   <div class=" textcol ">
                                        <h3>New Income</h3>
                                        <p>
                                             One time or regular customers everyday. No more chasing for new customers, visiting and providing quotes.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="illustration"></div>

          <div class="requirements">
               <div class="container">
                    <h2>
                         Requirements
                    </h2>
                    <div class="columns is-mobile is-vcentered">
                         <div class="icon ">
                              <svg width="103" height="103" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path
                                        d="M63.8597 20.9874C63.8092 20.4762 63.6297 19.9863 63.3382 19.5633C63.0467 19.1404 62.6526 18.7984 62.1929 18.5693L37.8596 6.40261C37.437 6.19115 36.9709 6.08105 36.4984 6.08105C36.0259 6.08105 35.5598 6.19115 35.1373 6.40261L10.8039 18.5693C10.3455 18.7997 9.9526 19.1421 9.66175 19.5649C9.37091 19.9876 9.19149 20.4769 9.14014 20.9874C9.10668 21.3129 6.21709 53.737 35.265 66.655C35.6531 66.8301 36.0741 66.9207 36.4999 66.9207C36.9258 66.9207 37.3467 66.8301 37.7348 66.655C66.7828 53.737 63.8932 21.3159 63.8597 20.9874ZM36.4999 60.5199C15.9109 50.5676 14.9376 29.3277 15.1048 23.223L36.4999 12.5255L57.8798 23.2169C57.9923 29.2668 56.8821 50.6467 36.4999 60.5199Z"
                                        fill="white"
                                   />
                                   <path
                                        d="M33.4581 38.2824L26.4835 31.3078L22.1826 35.6087L33.4581 46.8842L50.8169 29.5254L46.5159 25.2245L33.4581 38.2824Z"
                                        fill="white"
                                   />
                              </svg>
                         </div>
                         <div class="column">
                              Must be authorized to work in the United States of America
                         </div>
                    </div>

                    <div class="columns  is-mobile is-vcentered">
                         <div class="icon ">
                              <svg width="103" height="103" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path
                                        d="M63.8597 20.9874C63.8092 20.4762 63.6297 19.9863 63.3382 19.5633C63.0467 19.1404 62.6526 18.7984 62.1929 18.5693L37.8596 6.40261C37.437 6.19115 36.9709 6.08105 36.4984 6.08105C36.0259 6.08105 35.5598 6.19115 35.1373 6.40261L10.8039 18.5693C10.3455 18.7997 9.9526 19.1421 9.66175 19.5649C9.37091 19.9876 9.19149 20.4769 9.14014 20.9874C9.10668 21.3129 6.21709 53.737 35.265 66.655C35.6531 66.8301 36.0741 66.9207 36.4999 66.9207C36.9258 66.9207 37.3467 66.8301 37.7348 66.655C66.7828 53.737 63.8932 21.3159 63.8597 20.9874ZM36.4999 60.5199C15.9109 50.5676 14.9376 29.3277 15.1048 23.223L36.4999 12.5255L57.8798 23.2169C57.9923 29.2668 56.8821 50.6467 36.4999 60.5199Z"
                                        fill="white"
                                   />
                                   <path
                                        d="M33.4581 38.2824L26.4835 31.3078L22.1826 35.6087L33.4581 46.8842L50.8169 29.5254L46.5159 25.2245L33.4581 38.2824Z"
                                        fill="white"
                                   />
                              </svg>
                         </div>
                         <div class="column">
                              Must have prior customer servicing experience with lawn mowing and trimming
                         </div>
                    </div>
                    <div class="columns is-mobile is-vcentered">
                         <div class="icon ">
                              <svg width="103" height="103" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path
                                        d="M63.8597 20.9874C63.8092 20.4762 63.6297 19.9863 63.3382 19.5633C63.0467 19.1404 62.6526 18.7984 62.1929 18.5693L37.8596 6.40261C37.437 6.19115 36.9709 6.08105 36.4984 6.08105C36.0259 6.08105 35.5598 6.19115 35.1373 6.40261L10.8039 18.5693C10.3455 18.7997 9.9526 19.1421 9.66175 19.5649C9.37091 19.9876 9.19149 20.4769 9.14014 20.9874C9.10668 21.3129 6.21709 53.737 35.265 66.655C35.6531 66.8301 36.0741 66.9207 36.4999 66.9207C36.9258 66.9207 37.3467 66.8301 37.7348 66.655C66.7828 53.737 63.8932 21.3159 63.8597 20.9874ZM36.4999 60.5199C15.9109 50.5676 14.9376 29.3277 15.1048 23.223L36.4999 12.5255L57.8798 23.2169C57.9923 29.2668 56.8821 50.6467 36.4999 60.5199Z"
                                        fill="white"
                                   />
                                   <path
                                        d="M33.4581 38.2824L26.4835 31.3078L22.1826 35.6087L33.4581 46.8842L50.8169 29.5254L46.5159 25.2245L33.4581 38.2824Z"
                                        fill="white"
                                   />
                              </svg>
                         </div>
                         <div class="column">
                              Must have general liability insurance
                         </div>
                    </div>
               </div>
               <div class="warning">Nestease is not an employer, but we simply connect service providers with customers.</div>
          </div>

          <div class="download container">
               <h2>
                    Partner with us today!
               </h2>

               <p>
                    Download the Nestease Partner app to get started
               </p>

               <div class="appstores">
                    <a href="https://apps.apple.com/in/app/nestease-partner/id1539485053" target="_blank"
                         ><img src="@/assets/images/appstore.png" alt=""
                    /></a>

                    <a href="https://play.google.com/store/apps/details?id=com.nestease.partner" target="_blank"
                         ><img src="@/assets/images/playstore.png" alt=""
                    /></a>
               </div>

               <!-- <div class="mt-6">
                    <form class="joinForm " @submit.prevent="waitinglist" style="display:flex;justify-content:center;">
                         <b-field label="">
                              <b-input type="phone" placeholder="Contact number" required size="is-medium" v-model="email"></b-input>

                              <p class="control">
                                   <b-button class="button is-primary" size="is-medium" native-type="submit" :loading="isloading">Submit </b-button>
                              </p>
                         </b-field>
                    </form>
               </div> -->
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .home {
     }

     .container {
          padding-left: 15px;
          padding-right: 15px;
     }

     .banner {
          .container {
               max-width: 1200px !important;
          }
          min-height: 700px;
          padding: 50px 0 100px;
          background: white;

          @media screen and (max-width: 704px) {
               padding: 10px 0 100px;
          }

          h1 {
               font-size: 60px;

               @media screen and (max-width: 904px) {
                    font-size: 37px;
                    line-height: 49px;
               }

               line-height: 65px;
               margin-bottom: -2px;
          }

          .handimg {
               text-align: right;
               img {
                    height: 600px;
                    width: auto;
               }

               @media screen and (max-width: 704px) {
                    text-align: center;
                    padding: 10px;
                    img {
                         height: auto;
                         width: 100%;
                         display: block;
                         margin: auto;
                    }
               }
          }

          // @media screen and (max-width: 1024px) {
          //      background: url("../assets/images/home/bannerhand.jpg");

          //      background-repeat: no-repeat;
          //      background-position: center center;
          //      height: 500px;
          // }
          // .container {
          //      @media screen and (max-width: 1024px) {
          //           align-items: center;
          //           display: flex;
          //           flex-direction: column;
          //           justify-content: center;
          //           height: 100%;
          //      }
          // }
          .bannertext {
               padding: 30px 0;
               @media screen and (max-width: 1024px) {
                    padding: 20px 10px;
               }
          }

          p {
               margin-top: 30px;
               font-family: "DM SANS";
               font-size: 20px;
               color: black;
               .location {
                    svg {
                         position: relative;
                         top: 5px;
                    }
                    // font-family: "DM SANS";
                    font-weight: 600;
                    color: black;
               }
          }

          .appstores {
               margin-top: 20px;

               @media screen and (max-width: 704px) {
                    margin-top: 10px;
                    margin-bottom: 20px;
               }

               img {
                    height: 50px;
                    width: auto;
                    margin-right: 10px;

                    @media screen and (max-width: 704px) {
                         height: 47px;
                         width: auto;
                    }
               }
          }
     }
     .howitworks {
          padding: 100px 0 150px;
          @media screen and (max-width: 900px) {
               padding-top: 80px;
          }
          .imgcol {
               text-align: right;
               padding-right: 20px;

               @media screen and (max-width: 1200px) {
                    text-align: center;
                    padding-right: 0;
               }
          }
          .textcol {
               padding-left: 50px;
          }

          .steps {
               margin-top: 30px;
               .columns {
                    margin-bottom: 25px;
               }
               .count {
                    background: #03cf92;
                    color: white;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    font-size: 23px;
                    font-weight: 600;
                    font-family: "DM Sans", sans-serif;
               }
               .step {
                    padding-left: 20px;
                    font-weight: 600;
                    font-size: 23px;

                    @media screen and (max-width: 900px) {
                         font-size: 21px;
                    }
               }
          }

          .mobile.steps {
               width: 300px;
               margin: auto;
               margin-top: 30px;
               margin-bottom: 45px;
          }
     }

     div.why {
          margin-top: 30px;
          h2 {
               text-align: center;
          }

          .container {
               max-width: 1000px;
          }
          .subtext {
               max-width: 600px;
               margin: auto;
               text-align: center;
               margin-top: 20px;
          }

          .itemwhy {
               margin-top: 80px;

               .iconcol {
                    text-align: center;

                    img {
                         height: 60px;
                         width: auto;
                    }

                    .savemoneyicon {
                         transform: scale(1.2);
                    }
               }

               .textcol {
                    text-align: center;
                    h3 {
                         margin-top: 20px;
                    }
                    p {
                         max-width: 260px;
                         text-align: justify;
                         margin: auto;
                         margin-top: 10px;
                    }
               }
          }
     }

     .illustration {
          margin-top: 30px;
          background: url("../assets/images/background-illustration.png");
          height: 500px;
          background-size: cover;
          background-position: bottom center;
          background-repeat: no-repeat;

          @media screen and (max-width: 700px) {
               height: 300px;
               background-position: bottom center;
               background-repeat: no-repeat;
               background-size: cover;
          }
     }

     .requirements {
          background: #0fcb92;
          padding: 110px 0;
          margin-top: -4px;
          color: white;

          .container {
               max-width: 650px !important;
               margin: auto;
          }

          .columns {
               margin-bottom: 40px !important;
          }
          .icon {
               width: 50px;
               height: 50px;
               margin-right: 20px;
               svg {
               }
          }

          .column {
               color: white;
               font-size: 20px;
               font-weight: 600;
          }

          h2 {
               text-align: center;
               color: white;
               margin-bottom: 50px;
          }
          .warning {
               text-align: center;
               margin-top: 70px;
               color: white;
          }
     }

     .download {
          text-align: center;
          padding: 150px 0;

          @media screen and (max-width: 900px) {
               padding: 100px 10px;
          }

          p {
               font-size: 20px;
               margin-top: 10px;

               @media screen and (max-width: 900px) {
                    font-size: 19px;
                    max-width: 300px;
                    margin: auto;
               }
          }

          .appstores {
               margin-top: 40px;
               img {
                    height: 50px;
                    width: auto;
                    margin-right: 10px;
               }
          }
     }
</style>
