<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">Careers</h1>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container content header-content">
        <h1 class="welcome-text">Careers</h1>

        <p class="thankYou">
          Join Nestease and be part of a team that’s shaping the future. Explore
          opportunities to grow, innovate, and make an impact with us.
        </p>
      </div>
    </div>

    <div class="custpartner">
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50">
            <div class="nester">
              <div class="img-style founder"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Software Engineer</h2>
              <p>
                At Nestease, as a Software Engineer, you will take charge of
                architecting, designing, and maintaining end-to-end scalable
                software systems that drive our platform. This role involves
                tackling complex technical challenges, optimizing user
                experiences, and ensuring system efficiency. You will contribute
                to startup engineering projects, including mobile app
                development, backend development, and integrating cutting-edge
                AI/ML solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50 is-hidden-tablet">
            <div class="nester">
              <div class="img-style ourInHouse"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Product Engineer</h2>
              <p>
                At Nestease, as a Product Engineer, your journey begins with
                understanding customer needs and transforming them into
                impactful products. You will design and develop solutions that
                seamlessly integrate technology with user experience, creating
                intuitive features aligned with business objectives. As part of
                your role, you will directly onboard users, ensuring they have a
                smooth experience with the platform, gathering feedback, and
                iterating on the product to enhance usability and value. You
                will take full ownership of product modules, driving them from
                ideation to successful deployment while continuously improving
                based on user insights.
              </p>
            </div>
          </div>
          <div class="width-50 is-hidden-mobile">
            <div class="nester">
              <div class="img-style ourInHouse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container content footer-content">
      <h1 class="welcome-text">Why Join Nestease?</h1>

      <p class="thankYou">
        At Nestease, we value innovation, teamwork, learning, and a
        customer-first approach. Be part of a passionate team transforming home
        services to make life easier for our customers. <br> Reach out to us at
        <a class="contect-com" href="mailto:careers@nestease.com" target="_blank">careers@nestease.com</a> to join our mission!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/career/career-img-bg.jpg");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.nester {
  height: 550px;
  .img-style {
    height: 100%;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.ourstoryImage {
  background: url("../assets/images/about_us/Container.jpg");
  @media screen and (max-width: 800px) {
    background: url("../assets/images/about_us/Container_m.png");
  }
}
.founder {
  background: url("../assets/images/career/software-bg.png");
  @media screen and (max-width: 600px) {
    background-image: url("../assets/images/career/software-bg.png");
  }
}

.flexibility {
  background: url("../assets/images/about_us/Flexibility.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/Flexibility_m.png");
  }
}

.ourInHouse {
  background: url("../assets/images/career/product-bg.png");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/career/product-bg.png");
  }
}

.lookingToward {
  background: url("../assets/images/about_us/LookingToward.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/LookingToward_m.png");
  }
}

.ourCustomers {
  background: url("../assets/images/about_us/OurCustomers.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurCustomers_m.png");
  }
}

.ourPartners {
  background: url("../assets/images/about_us/OurPartners.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurPartners_m.png");
  }
}

.content {
  padding-top: 130px;
  padding-bottom: 130px;
  .welcome-text {
    font-weight: 500;
    font-weight: bold;
  }
  .thankYou {
    font-weight: 500;
    font-size: 20px;
    max-width: 740px;
    margin: auto;
  }
  // max-width: 700px !important;
  h1 {
    display: flex;
    justify-content: start;
  }

  h2 {
    font-weight: 500;
    text-align: start;
  }

  p {
    text-align: start;
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.custpartner {
  // margin: 50px auto 130px;
  padding: 130px 288px;
  background-color: #eff8f5;

  h1 {
    font-size: 50px;
    max-width: 100%;
  }

  .content {
    padding: 60px 30px;
  }

  @media screen and (max-width: 1400px) {
    padding: 100px 16px;
  }

  @media screen and (max-width: 769px) {
    padding: 100px 16px;
  }
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }
}
.header-content, .footer-content {

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 35.7px;
  }

  p.thankYou {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 20px;
  }
}

.footer-content {
  padding-left: 100px;
  padding-right: 100px;
  max-width: 910px;


  p.thankYou {
    display: inline;
    max-width: 910px;
    text-align: center;
    align-items: center;
    font-weight: 600;
  }
  a.contect-com {
    display: inline;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #0B0D17;
  }
}

</style>
