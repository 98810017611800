<template>
  <div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  methods: {
    scrollToVideoPlayer() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log('window.opera :>> ', navigator.userAgent);

      if (/android/i.test(userAgent)) {
        const url = "https://play.google.com/store/apps/details?id=com.nestease.customer";
        window.location.href = url;
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        const url = "https://apps.apple.com/in/app/nestease/id1539350208";
        window.location.href = url;
      } else {
      }
    },
  },
  mounted() {
    if (this.id) {
      this.scrollToVideoPlayer();
    }
  },
};
</script>
