<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">ToS and License Agreement</h1>
            <p class="date">From December 03, 2024</p>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container content">
        <div style="margin: 16px">
          <h2>Terms of Service</h2>
          <p>
            The Nestease app and the site (together called the "Site") are
            created and operated by Nestease, LLC, a Virginia limited liability
            company ("Nestease"). When you use the service(s) provided and
            offered on the Site (the "Services"), you are agreeing to these
            Terms and Conditions (“Terms”) between you and Nestease.
          </p>

          <p>
            Nestease has developed a platform (“Platform”) that (i) allows
            customers (collectively, “Clients”) to access to services provided
            by Nestease, its subcontractors, or its third party labor pool
            (these third party providers referred to as “Providers”) for the
            provision of services to or for the benefit of each Client, (ii)
            allows Clients to create appointment requests for the performance
            and delivery of services (“Requested Services”), (iii) allows
            Nestease, its subcontractors, or Providers to access appointment
            requests for Requested Services, (iv) allows Nestease, its
            subcontractors, or Providers to accept appointments for Requested
            Services, and (v) facilitates scheduling of Requested Services,
            provides appointment reminders, and provides for payment. Nestease
            service agreements with Clients are binding on Client. Nestease, its
            subcontractors, and Providers are engaged by Client, at Client’s
            option, on Client’s terms. You acknowledge and agree that
            non-Nestease Providers are not interviewed, vetted, selected,
            approved, contracted, or hired by Nestease.
          </p>

          <p>
            Pricing for Services will be established by Client input and the
            details of each Requested Service, and may also be confirmed,
            altered, or countered by publicly available information such as home
            lot size, square footage. Nestease, its subcontractors, and
            Providers may alter a proposed price for a Requested Service prior
            to performance, or may adjust the pricing of a subsequent service
            based on information learned on a first service call.
          </p>

          <p>
            Nestease has the right, at its discretion, to change, modify, add or
            remove certain of these terms or the financial and pricing terms for
            its Platform and Services over time. The then-current version of
            these Terms will govern your interactions with the Site and the
            Services.
          </p>

          <p>
            We may change or discontinue any aspect of the Site or the Services
            at any time, including the availability of any feature, function, or
            content. We may limit features and Services or restrict your access
            to parts or all of the Site or the Services provided without notice
            or liability.
          </p>

          <p>
            Use of the Site or the Service is void where prohibited. This Site
            is intended for users within the United States who are eighteen (18)
            years of age or older. By using the Service or the Site, you
            represent and warrant that you are 18 or older, and that you agree
            to and to abide by these Terms.
          </p>

          <p>
            By using the Platform, the Site, or the Services, all parties agree
            to the Nestease Privacy Policy on the Site.
          </p>

          <h2>REPRESENTATIONS AND WARRANTIES</h2>
          <p>
            Neither Nestease, nor any of our affiliates, employees, agents,
            content providers or licensors make any representations or
            warranties of any kind regarding the Site or the Services, the
            information presented, or the results that may be obtained from use
            of the Site. You agree that delivery of services to you by Providers
            and the liability for any action, failure to act, loss, or injury
            relies solely upon such Providers and not on Nestease.
          </p>

          <p>
            You acknowledge and agree that Nestease is a company that provides
            help with the dispatch, management, and tracking of independent
            contractors, freelance service providers, and mobile workforces
            (“Nestease”).
          </p>

          <p>
            If you have any questions about, or information to share concerning
            this Site, the Service, or any Providers, please email us at
            contactus@nestease.com
          </p>

          <!-- <ul>
            <li style="font-weight: 400">
              Customers are solely responsible for compensation to Providers
            </li>
            <li style="font-weight: 400">
              Providers are solely responsible for providing services to
              Customers, the acquisition and maintenance of any required
              licenses related to their services, and for any related actions,
              damages, claims, acts, or failures to act
            </li>
            <li style="font-weight: 400">
              Providers are not employees or subcontractors of Nestease;
              Providers are independent users of the Service
            </li>
            <li style="font-weight: 400">
              Nestease is not licensed in any state or nation, as a contractor
              or otherwise, with respect to the delivery of any services by
              itself or a Provider
            </li>
            <li style="font-weight: 400">
              Nestease is not a Customer or a Provider
            </li>
          </ul> -->

          <!-- <p>
            Your Content and Information .&nbsp;You represent and warrant that
            you will provide only accurate, complete, and non-misleading content
            and data to the Service. You further represent and warrant that you
            will act quickly to correct any unintentional errors with regard to
            Your Content (as defined herein). Each Provider and each Customer
            will be solely responsible for any and all service descriptions,
            pricing data, photos, text, graphics, videos, images, or other
            material that it uploads to the Service (“Your Content”). You grant
            us a non-exclusive, fully paid, royalty-free license to access, use,
            copy, modify (including the right to create derivative works),
            display, reproduce, and transmit Your Content for the provision of
            the Service by Nestease and its partners and necessary service
            providers. You agree that Nestease may use Your Content to suggest
            or promote services that may be of interest to you, at any time.
          </p>

          <p>
            You are responsible for the accuracy, content, intellectual property
            rights and licenses, and legality of Your Content, and any indexing,
            communication, display, or transfer of Your Content outside of the
            Service by you or any third-party authorized by you. You represent,
            warrant and covenant that you have all rights necessary to upload
            Your Content to the Service and to otherwise have Your Content used
            and shared thereafter.
          </p> -->

          <h2>INDEMNITY/LIMITATIONS OF LIABILITY</h2>

          <p>
            As a condition of use of the Site and the Services, you agree to
            indemnify, defend and hold harmless Nestease, and all officers,
            directors, owners, agents, information providers, affiliates,
            licensors and licensees from and against any and all claims,
            liability and costs, including attorneys' reasonable fees, arising
            from or relating to any allegation regarding (i) your use of the
            Site and/or Services, (ii) Nestease's use or distribution of any
            content or information you provide, (iii) information or material
            posted or transmitted through your account, even if not posted by
            you, and (iv) any violation by you, or others using your account, of
            these Terms And Conditions.
          </p>

          <p>
            As a Client, you acknowledge and agree that Nestease shall not be
            liable for the actions or failures to act of Providers, or of any
            non-Nestease party. You further acknowledge and agree that Nestease,
            its subcontractors, or Providers may use tools or equipment in the
            provision of services to you, for example lawn mowers, vacuums, or
            other devices that feature or rely upon robotic, self-driving, or
            machine learning technologies. You agree that when engaging any
            Provider for any services at your home or other premises, you will
            make your best efforts to clear the necessary areas of obstacles and
            dangers in order to enable the safe delivery of services to you.
          </p>

          <p>
            If you are a Provider, you acknowledge and agree that Nestease shall
            not be liable for the actions or failures to act of Clients,
            Clients’ guests or invitees,other Providers, your guests or
            invitees, or any non-Nestease party.
          </p>

          <p>
            You shall cooperate as fully as reasonably required in the defense
            of any such claim. Nestease reserves the right, at its own expense,
            to assume the exclusive defense and control of any matter subject to
            indemnification by you.
          </p>

          <p>
            Under no circumstances, including negligence, shall Nestease, or its
            directors, officers, employees, agents or affiliates, be liable for
            any indirect, incidental, special or consequential damages,
            including for any lost profits or lost data, that result from the
            use of, or the inability to use, the Platform, unauthorized access
            to or alteration of your transmissions or data, or any errors or
            omissions in the information presented by the Platform, even if
            Nestease is aware or has been advised of the possibility of such
            damages. You specifically acknowledge and agree that Nestease is not
            liable for any conduct of any user.
          </p>

          <p>
            Certain state laws do not allow limitations on implied warranties or
            the exclusion or limitation of certain damages. If these laws apply
            to you, some or all of the above disclaimers, exclusions or
            limitations may not apply to you, and you may have additional
            rights.
          </p>

          <p>
            If you are dissatisfied or harmed by Nestease's services, the
            actions or failures of Nestease, a Nestease subcontractor, a
            Provider, or any other Site or Platform user, any Nestease partner,
            third party, any Nestease material, or with any of Nestease's terms
            and conditions, your remedy is limited to your decision to
            discontinue using the Site, together with the return to you of any
            net payment or profit received by Nestease directly in connection
            with the relevant Service Request and the facts of your claim. You
            agree that Nestease shall not be liable to you or to any third
            parties for any actions or failures of any Provider, any other Site
            user, or any Nestease partner.
          </p>

          <h2>RELATIONSHIP WITH PROVIDERS</h2>

          <p>
            With respect to Providers only, the Platform exists to help you
            manage your Providers and their assignments and performance. You
            acknowledge and agree that this is the limitation of the role of
            Nestease and the Platform to you. Providers are not employees,
            subcontractors, officers, or agents of Nestease, and Nestease is not
            responsible for Providers’ actions or failures to act. When you work
            with a Providers, your rights will be governed by and limited to
            your agreement with that Providers. Providers will typically
            interact with the Platform and Service by means of the Site or a
            mobile app, under these terms and conditions. These terms and
            updates to them will remain available for your review here
            <a href="https://nestease.com/terms">https://nestease.com/terms</a>
          </p>

          <h2>PROVIDER TERMS</h2>

          <p>
            Providers agree that they are not employees, agents, or officers or
            Nestease, but are solely users of the Platform for purposes of
            finding, booking, and coordinating service opportunity for Clients.
            Each Provider therefore represents and warrants to Clients and to
            Nestease that:
          </p>

          <ul>
            <li style="font-weight: 400">
              Provider has insurance sufficient to cover its potential liability
              for the Services offered, scheduled, and associated with Nestease,
              Clients, and the Platform
            </li>
            <li style="font-weight: 400">
              Provider shall be liable for and insure against its own losses,
              injuries, or property damage related to the Services offered,
              scheduled, and associated with Nestease, Clients, and the Platform
            </li>
          </ul>

          <p>
            If a Client supplies tools, equipment, or supplies to Nestease, its
            subcontractors, or Providers in connection with any Service Request,
            Client assumes full risk and responsibility for any use, damage,
            loss, harm, or impairment of such tools, equipment, or supplies.
          </p>

          <h2>OUR THIRD PARTY SERVICE PROVIDERS FOR THE PLATFORM</h2>

          <p>
            We use recognized and trusted partners to create and deliver the
            Platform to you. These include cloud-based service providers for web
            server and database hosting, contact form and email communications,
            call center, software developers, chat functions from our web site,
            map and navigational functions, and other services provided by
            trusted partners of ours. By using the Platform, you agree to our
            use of these third party services for the limited purposes of
            delivering the Site and the Platform to you and our other users, and
            maintaining its full functionality.
          </p>

          <h2>RESTRICTIONS</h2>

          <p>
            You may not: (i) remove any copyright, trademark or other
            proprietary notices from any portion of the Platform, Site or
            Services; (ii) reproduce, modify, prepare derivative works based
            upon, distribute, sell, resell, transfer, publicly display, publicly
            perform, transmit, or otherwise exploit any aspect or portion of the
            Site or Services except as expressly permitted by us; (iii) mirror
            or frame any portion of the Site or Services; (v) scrape, index,
            harvest, survey, or otherwise copy, mine or collect any portion of
            the Site, Services, or their content or data; or (vi) unduly burden
            or hinder the operation and/or functionality of any aspect of the
            Site or Services or their systems or networks.
          </p>

          <h2>DISCLAIMER</h2>

          <p>
            ALL SERVICES AND INFORMATION IN THE SITE, PLATFORM, AND THIRD-PARTY
            SITES TO WHICH IT LINKS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY
            KIND. THE CONTENT PUBLISHED ON THE SITE MAY INCLUDE INACCURACIES OR
            TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY MADE TO THE
            INFORMATION HEREIN. NESTEASE AND/OR ITS SUPPLIERS MAY MAKE
            IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME, INCLUDING
            CHANGES TO THESE TERMS OF USE. NESTEASE AND/OR ITS SUPPLIERS MAKE NO
            REPRESENTATIONS AND, TO THE FULLEST EXTENT ALLOWED BY LAW, DISCLAIM
            ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE REGARDING THE
            SUITABILITY OF THE INFORMATION; THE ACCURACY, RELIABILITY,
            COMPLETENESS OR TIMELINESS OF THE CONTENT, SERVICES, PRODUCTS, TEXT,
            GRAPHICS, LINKS, OR OTHER ITEMS CONTAINED WITHIN THE SITE, OR THE
            RESULTS OBTAINED FROM ACCESSING AND USING THE SITE AND/OR THE
            CONTENT CONTAINED HEREIN. NESTEASE DOES NOT WARRANT THAT THE
            FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR
            ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE,
            INCLUDING THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. THE USER ASSUMES THE ENTIRE COST OF ALL
            NECESSARY MAINTENANCE, REPAIR OR CORRECTION OF THE USER'S COMPUTER
            AND OTHER EQUIPMENT.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/terms/Tos-desk-bg.png");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.content {
  padding-top: 50px;
  padding-bottom: 100px;
  h2 {
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 50px !important;
    max-width: 650px;
  }

  p {
    text-align: justify;
    max-width: 650px;
  }

  li {
    text-align: justify;
    max-width: 500px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container {
  padding-left: 16px;
  padding-right: 16px;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  p.date {
    color: white;
    font-size: 20px;
    align-self: center;
    margin-top: 20px;
    font-weight: bold;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }
}
</style>
