<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">About us</h1>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container content">
        <h1 class="welcome-text">
          Welcome to Nestease: Your Home Needs Made Easy!
        </h1>

        <p>
          Nestease is a technology company transforming home services to offer
          flexibility, convenience, and top-notch quality—all delivered by our
          dedicated in-house professionals. Our mission is to make life easier
          by providing reliable, customer-focused services that you can trust.
          We started with lawn care, addressing the challenges of high costs,
          rigid contracts, and the time-consuming nature of traditional
          solutions. Nestease offers a better way: flexible, recurring, and
          on-demand services without the hassle of contracts or hidden fees.
          Whether it’s lawn care, snow removal, house cleaning, or other home
          services, our team delivers personalized, high-quality solutions
          tailored to your needs.
        </p>

        <p>
          Using our intuitive technology platform, you can seamlessly book
          services and enjoy a hassle-free experience. At Nestease, we believe
          the future of home services is about accessibility, efficiency, and
          personalization.
        </p>
        <h2 class="thankYou">
          Thank you for choosing Nestease—because great things truly start at
          home.
        </h2>
      </div>
    </div>
    <div class="ourstory">
      <div class="img-style ourstoryImage">
        <h1>Our story</h1>
      </div>
    </div>
    <!-- <div class="">

               <div class="container content">
                    <h2>We founded Nestease to help communities with their home care needs.</h2>
                    <p>
                         Our first goal was to bring people and resources together and optimize the lawn mowing industry
                         to benefit everyone. With that
                         goal in mind, we launched our first product to provide customers the ability to select lawn
                         mowing service on-demand with a
                         wide range of local and community providers using a smartphone. Through our product, we empower
                         professionals, local
                         businesses and community providers to earn new income by connecting them to a set of new
                         customers that are now so easily
                         accessible.
                    </p>
               </div>
          </div> -->

    <div class="custpartner">
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50">
            <div class="nester">
              <div class="img-style founder"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Rakesh Nair - Founder</h2>
              <p>
                Nestease was founded by Rakesh Nair, a seasoned leader in
                technology and product development with a background at Capital
                One, Fiserv, Wipro, and Birla. With a mission to make home
                services simpler and more convenient, Rakesh created Nestease to
                address homeowners' need for flexibility and ease. His
                commitment to listening to customers is reflected in every
                aspect of Nestease, from its user-friendly interface to its
                adaptable service options, summed up by the company's motto:
                “Your Home Needs Made Easy.”
              </p>
              <p>
                Rakesh holds a Master of Engineering in Systems Engineering from
                the University of Virginia, an Executive MBA from the University
                of Nebraska, and a Bachelor's degree in Computer Science from
                M.S. University in India. As Nestease grows, he remains
                committed to refining the customer experience through ongoing
                user feedback.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50 is-hidden-tablet">
            <div class="nester">
              <div class="img-style ourInHouse"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Our In-House Experts</h2>
              <p>
                Unlike traditional home service models, Nestease relies solely
                on a dedicated in-house team of local professionals. This
                in-house approach ensures every service meets our high standards
                of reliability, quality, and consistency. Our experts are
                passionate about delivering personalized care that's tailored to
                your unique needs.
              </p>
            </div>
          </div>
          <div class="width-50 is-hidden-mobile">
            <div class="nester">
              <div class="img-style ourInHouse"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50">
            <div class="nester">
              <div class="img-style flexibility"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Flexibility, Control, and Convenience</h2>
              <p>
                We understand that your time is valuable. That's why our
                easy-to-use app gives you complete control—schedule your
                services, pause, switch, or cancel with just a few taps. Whether
                you need regular services or a one-time solution, you have the
                flexibility to choose what fits your schedule and budget.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50 is-hidden-tablet">
            <div class="nester">
              <div class="img-style lookingToward"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Looking Toward the Future</h2>
              <p>
                Nestease is just getting started! While we currently specialize
                in personalized lawn care, we are rapidly expanding our platform
                to offer a wide range of home services, including cleaning,
                maintenance, repairs, and much more. Our goal is to offer you
                the ultimate convenience—manage all your home service needs in
                one place with the same personalized attention you've come to
                trust.
              </p>
            </div>
          </div>
          <div class="width-50 is-hidden-mobile">
            <div class="nester">
              <div class="img-style lookingToward"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50">
            <div class="nester">
              <div class="img-style ourCustomers"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Our Customers - The “nester”</h2>
              <p>
                Join the Nestease community today and experience a new standard
                of home service convenience, quality, and flexibility.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-vcentered m-0">
          <div class="width-50 is-hidden-tablet">
            <div class="nester">
              <div class="img-style ourPartners"></div>
            </div>
          </div>
          <div class="width-50">
            <div class="container content">
              <h2>Our Partners - The “easer”</h2>
              <p>
                Enjoy financial freedom and flexibility while being part of a
                dedicated, high-quality, and growing team.
              </p>
            </div>
          </div>
          <div class="width-50 is-hidden-mobile">
            <div class="nester">
              <div class="img-style ourPartners"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/about_us/Background2.jpg");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.ourstory {
  height: 450px;
  margin-bottom: 200px;
  .img-style {
    height: 100%;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    h1 {
      display: flex;
      color: white;
      font-size: 80px;
      text-align: center;
    }
  }
}

.nester {
  height: 550px;
  .img-style {
    height: 100%;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.ourstoryImage {
  background: url("../assets/images/about_us/Container.jpg");
  @media screen and (max-width: 800px) {
    background: url("../assets/images/about_us/Container_m.png");
  }
}
.founder {
  background: url("../assets/images/about_us/Founder.jpg");
  @media screen and (max-width: 600px) {
    background-image: url("../assets/images/about_us/Founder_m.png");
  }
}

.flexibility {
  background: url("../assets/images/about_us/Flexibility.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/Flexibility_m.png");
  }
}

.ourInHouse {
  background: url("../assets/images/about_us/OurInHouse.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurInHouse_m.png");
  }
}

.lookingToward {
  background: url("../assets/images/about_us/LookingToward2.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/LookingToward2_m.jpg");
  }
}

.ourCustomers {
  background: url("../assets/images/about_us/OurCustomers.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurCustomers_m.png");
  }
}

.ourPartners {
  background: url("../assets/images/about_us/OurPartners.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurPartners_m.png");
  }
}

.content {
  padding-top: 70px;
  padding-bottom: 70px;
  .welcome-text {
    font-weight: 500;
    margin-bottom: 40px;
  }
  .thankYou {
    font-weight: 500;
    font-size: 20px;
  }
  // max-width: 700px !important;
  h1 {
    display: flex;
    justify-content: start;
  }

  h2 {
    font-weight: 500;
    text-align: start;
  }

  p {
    text-align: justify;
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.custpartner {
  margin: 50px auto 130px;

  h1 {
    font-size: 50px;
    max-width: 100%;
  }

  .content {
    padding: 60px 30px;
  }
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }
}
</style>
